import React from 'react';
import { img0, img1, img2, img3, img4, img5 } from '../assets/images';

const images = [img0, img1, img2, img3, img4, img5];

const Result = (props) => {
  return (
    <div style={styles.resultWrapper}>
      {props.isOpen ? (
        <div style={styles.itemWrapper}>
          <div style={styles.itemTopLine}>
            <img style={styles.itemImage} src={images[props.res[0]]} alt={`Item ${props.res[0]}`} />
            <img style={styles.itemImage} src={images[props.res[1]]} alt={`Item ${props.res[1]}`} />
          </div>
          <div style={styles.itemBottomLine}>
            <img style={styles.itemImage} src={images[props.res[2]]} alt={`Item ${props.res[2]}`} />
          </div>
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
};

const styles = {
  resultWrapper: {
    height: '400px',
  },
  itemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginTop: '16px', // Thêm margin top để dịch xuống
  },
  itemTopLine: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  itemBottomLine: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemImage: {
    height: '80px',
    width: '80px',
    borderRadius: '20px',
  },
  itemText: {
    fontSize: '20px',
    marginRight: '50px',
  },
};

export default Result;
