import React from 'react';

const Control = (props) => {
  return (
    <div style={styles.controlArea}>
      <button style={styles.buttonPlay} onClick={props.handlePlay}>
        ĐỔ QUÂN
      </button>
      <button
        style={props.isOpen ? styles.buttonClose : styles.buttonOpen}
        onClick={props.handleOpen}
      >
        {props.isOpen ? 'ĐÓNG BÁT' : 'MỞ BÁT'}
      </button>
    </div>
  );
};

const styles = {
  controlArea: {
    backgroundColor: '#abb8cf',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  buttonPlay: {
    color: 'white',
    backgroundColor: 'blue',
    margin: '10px',
    padding: '12px',
    width: '150px',
    borderRadius: '20px',
    border: 'none',
    cursor: 'pointer',
  },
  buttonOpen: {
    color: 'white',
    backgroundColor: 'green',
    margin: '10px',
    padding: '12px',
    width: '150px',
    borderRadius: '20px',
    border: 'none',
    cursor: 'pointer',
  },
  buttonClose: {
    color: 'white',
    backgroundColor: 'red',
    margin: '10px',
    padding: '12px',
    width: '150px',
    borderRadius: '20px',
    border: 'none',
    cursor: 'pointer',
  },
};

export default Control;
