import React from 'react';

const Header = () => {
  return (
    <div style={styles.headerWrapper}>
      <h1 style={styles.titleText}>Bầu Cua Tôm Cá</h1>
      <p style={styles.slogan}>Trò chơi dân gian quen thuộc Việt Nam</p>
    </div>
  );
};

const styles = {
  headerWrapper: {
    height: '90px',
    backgroundColor: '#de9168',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    color: '#fff',
    fontSize: '25px',
  },
  slogan: {
    color: '#000',
    fontSize: '15px',
    margin: '10px',
  },
};

export default Header;
