import React from 'react';
import Header from './components/Header';
import Body from './components/Body';
import Foot from './components/Foothis';

const Root = () => {
  return (
    <div>
      <Header />
      <Body />
      <Foot />
    </div>
  );
};

export default Root;
