import React from 'react';

const Foothis = () => {
  return (
    <div style={styles.headerWrapper}>
      <h1 style={styles.titleText}>LỊCH SỬ</h1>
    </div>
  );
};

const styles = {
  headerWrapper: {
    height: '340px',
    backgroundColor: '#de9168',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    color: '#fff',
    fontSize: '25px',
  },
};

export default Foothis;
