import React, { useState } from 'react';
import Result from './Resul';
import Control from './Control';

const Body = () => {
  const [itemList, setItemList] = useState([0, 0, 0]);
  const [isOpen, setIsOpen] = useState(false);

  const handlePlay = () => {
    setIsOpen(false);
    let items = [];
    for (let i = 0; i < 3; i++) {
      let item = Math.floor(Math.random() * 6);
      items.push(item);
    }
    setItemList(items);
  };

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const backgroundImage = isOpen
    ? require('../assets/images/mobat.png')
    : require('../assets/images/dongbat.png');

    const styles = {
        bodyWrapper: {
          top:-16,
          width: '100%',
          height: '47vh',
          bottom: 0,
          backgroundImage: `url(${backgroundImage})`,
          position: 'relative',
          backgroundSize: 'cover', // Đảm bảo ảnh nền cover đúng kích thước
          backgroundRepeat: 'no-repeat', // Không lặp lại ảnh
          backgroundPosition: 'center center', // Canh giữa ảnh nền
        },
      };

  return (
    <div style={styles.bodyWrapper}>
      <Result res={itemList} isOpen={isOpen} />
      <Control isOpen={isOpen} handlePlay={handlePlay} handleOpen={handleOpen} />
    </div>
  );
};

export default Body;
